import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Center,
  Group,
  Loader,
  Table as MantineTable,
  Pagination,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useMemo } from "react";
import { useResultsColumns } from "./ResultsContext";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";
import { BiErrorCircle, BiInfoCircle } from "react-icons/bi";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { useClient } from "@hooks/use-client";
import type { GoldViewDef } from "./utils";

const TableResultDataParser = z.object({ data: z.record(z.unknown()).array() });

const CellContent: React.FC<{ value: unknown }> = ({ value }) => {
  if (!value) {
    return (
      <Text c="dimmed" size="sm" truncate="end">
        empty
      </Text>
    );
  }
  let finalValue = value;

  if (typeof finalValue === "object") {
    finalValue = JSON.stringify(value, null, 2);
  }

  return (
    <Box display={"flex"}>
      <Tooltip multiline maw={"25rem"} label={String(finalValue)}>
        <Text truncate="end">{String(finalValue)}</Text>
      </Tooltip>
    </Box>
  );
};

const useResultsData = (goldViewId: number, limit: number = 1_000_000) => {
  const { fetchAPIWithToken } = useClient();

  return useQuery({
    enabled: !!goldViewId,
    queryKey: ["resultsData", { goldViewId }, { limit }],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/gold/views/${goldViewId}/data?limit=${limit}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data for gold view ${goldViewId}`);
      }

      const { data } = TableResultDataParser.parse(await response.json());
      return data;
    },
  });
};

export const Table = ({
  lastSuccessGoldView,
  goldView,
}: {
  lastSuccessGoldView: GoldViewDef | undefined;
  goldView: GoldViewDef;
}) => {
  const { data: rawColumnData } = useResultsColumns(goldView);
  const { data, isPending } = useResultsData(goldView.id, 1000);

  const valueData = useMemo(() => {
    return data ?? [];
  }, [data]);

  const columnHelper = createColumnHelper<Record<PropertyKey, unknown>>();

  const columns = useMemo(() => {
    if (rawColumnData && rawColumnData.length > 0) {
      return rawColumnData.map(({ name, isNew }) =>
        columnHelper.accessor(name, {
          cell: (info) => <CellContent value={info.getValue()} />,
          header: () => (
            <Group gap={"xs"} style={{ flexWrap: "nowrap" }}>
              <Tooltip multiline maw={"25rem"} label={name}>
                <Text fw="bold" truncate="end">
                  {name}
                </Text>
              </Tooltip>
              {isNew && (
                <Tooltip label={"New data column created by your companion"}>
                  <Box flex={0}>
                    <Badge
                      size="sm"
                      display={"flex"}
                      variant="dot"
                      color="green"
                      c={"dimmed"}
                    >
                      new
                    </Badge>
                  </Box>
                </Tooltip>
              )}
            </Group>
          ),
        }),
      );
    }
    return [];
  }, [rawColumnData, columnHelper]);

  const table = useReactTable({
    data: valueData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
  });

  return (
    <Card flex={1} radius="lg">
      <Stack flex={1} mih={0}>
        <MantineTable.ScrollContainer flex={1} minWidth={0}>
          <MantineTable
            striped
            captionSide="top"
            layout="fixed"
            verticalSpacing={"md"}
          >
            <MantineTable.Caption ta={"left"}>
              <Group px={8} justify="space-between">
                <Text>{lastSuccessGoldView?.table_display_name ?? ""}</Text>
                <Tooltip
                  w={300}
                  multiline
                  label={goldView.error ?? lastSuccessGoldView?.metadata}
                >
                  <ActionIcon
                    color={goldView.error ? "red" : undefined}
                    variant="transparent"
                  >
                    {goldView.error ? (
                      <BiErrorCircle size={20} />
                    ) : (
                      <BiInfoCircle size={20} />
                    )}
                  </ActionIcon>
                </Tooltip>
              </Group>
            </MantineTable.Caption>
            <TableHeader table={table} />
            <TableBody table={table} />
          </MantineTable>
        </MantineTable.ScrollContainer>
        {
          /* Cannot put inside table, as div cannot be children of table */
          isPending ? (
            <Center flex={1}>
              <Loader />
            </Center>
          ) : table.getPageCount() > 1 ? (
            <Group justify="space-between">
              <Stack gap={4}>
                <Text c={"dimmed"} size="sm">
                  {table.getRowCount()} rows in total
                </Text>
                <Pagination
                  size={"sm"}
                  total={table.getPageCount()}
                  siblings={2}
                  boundaries={2}
                  value={table.getState().pagination.pageIndex + 1}
                  onChange={(val) => table.setPageIndex(val - 1)}
                />
              </Stack>
              <Stack gap={4}>
                <Text c={"dimmed"} size="sm" ta={"right"}>
                  Rows per page
                </Text>
                <Select
                  size="sm"
                  defaultValue={String(table.initialState.pagination.pageSize)}
                  allowDeselect={false}
                  data={["10", "20", "30", "40", "50"]}
                  value={String(table.getState().pagination.pageSize)}
                  onChange={(val) => table.setPageSize(Number(val))}
                />
              </Stack>
            </Group>
          ) : null
        }
      </Stack>
    </Card>
  );
};
