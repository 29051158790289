import React from "react";
import { Table as MantineTable } from "@mantine/core";
import { flexRender, type Table } from "@tanstack/react-table";

export const TableHeader: React.FC<{
  table: Table<Record<PropertyKey, unknown>>;
}> = ({ table }) => {
  return (
    <MantineTable.Thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <MantineTable.Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const value = header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext());

            return (
              <MantineTable.Th key={header.id} style={{ width: "15rem" }}>
                {value}
              </MantineTable.Th>
            );
          })}
        </MantineTable.Tr>
      ))}
    </MantineTable.Thead>
  );
};
