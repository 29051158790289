import React from "react";
import { Table as MantineTable } from "@mantine/core";
import { flexRender, type Table } from "@tanstack/react-table";

export const TableBody: React.FC<{
  table: Table<Record<PropertyKey, unknown>>;
}> = ({ table }) => {
  return (
    <MantineTable.Tbody>
      {table.getRowModel().rows.map((row) => (
        <MantineTable.Tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <MantineTable.Td key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </MantineTable.Td>
          ))}
        </MantineTable.Tr>
      ))}
    </MantineTable.Tbody>
  );
};
