import {
  ActionIcon,
  Box,
  Center,
  Group,
  Image,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  Transition,
  type StackProps,
} from "@mantine/core";
import React, { forwardRef } from "react";
import {
  BiInfoCircle,
  BiRename,
  BiSolidMagicWand,
  BiTrash,
} from "react-icons/bi";
import { MdOutlineCleaningServices } from "react-icons/md";
import classes from "./Columns.module.css";

import TransformedColumnImage from "@images/transformed-column.svg";
import { useHover } from "@mantine/hooks";
import type { DetailedReportColumn } from "@mm/shared/schemas/reports";
import { useReportBuilder } from "./ReportBuilderContext";

function getConsistentRandom(row: number, col: number, min = 40, max = 50) {
  // Create a seed based on row and column
  const seed = row * 1000 + col;

  // Simple hash function
  const hash = Math.sin(seed) * 10000;

  // Get the decimal part
  const decimal = hash - Math.floor(hash);

  // Scale to your range
  return Math.floor(decimal * (max - min + 1)) + min;
}

interface WrapperColumnProps extends StackProps {
  children: React.ReactNode;
}

export const WrapperColumn = forwardRef<HTMLDivElement, WrapperColumnProps>(
  ({ children, ...props }, ref) => {
    return (
      <Stack ref={ref} {...props} className={props.className}>
        {children}
      </Stack>
    );
  },
);

interface ColumnProps {
  column: DetailedReportColumn;
}

const formatDataSample = (data: unknown): string => {
  if (data === null || data == undefined) {
    return "";
  }

  // if the data is a string, we try to parse it as JSON
  // note that we don't return the result here, as we might want do perform more processing on the extracted JSON
  if (typeof data === "string") {
    try {
      data = JSON.parse(data);
    } catch {
      // ignore
    }
  }

  if (typeof data === "object") {
    // BigQuery returns object for date and time columns, we want to display the value
    if (data && Object.keys(data).length == 1 && "value" in data) {
      data = data.value;
    } else {
      return JSON.stringify(data);
    }
  }

  return String(data);
};

export const Column: React.FC<ColumnProps> = ({ column }) => {
  const { postMessage, isPosting } = useReportBuilder();
  const { hovered, ref } = useHover();

  return (
    <WrapperColumn ref={ref} w={"12rem"}>
      {/* Header */}
      <Group flex={0} gap={"xs"}>
        <Tooltip multiline maw={"25rem"} label={column.explanation}>
          <ActionIcon size="xs" color="blue" variant="transparent">
            <BiInfoCircle />
          </ActionIcon>
        </Tooltip>
        <Tooltip multiline maw={"25rem"} label={column.name}>
          <Text flex={1} truncate="end" fw={500}>
            {column.name}
          </Text>
        </Tooltip>
      </Group>

      {/* Data samples */}

      <Stack
        flex={1}
        pos={"relative"}
        style={{ overflow: "hidden" }}
        gap={"sm"}
        className={classes.fadeStack}
      >
        {column.type === "transformation" ? (
          <Center pos={"absolute"} h={"100%"}>
            <Stack
              flex={1}
              ta={"center"}
              px={"xs"}
              bg={
                "linear-gradient(180deg,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 20%, rgba(255,255,255,0.9) 80%, rgba(255,255,255,0) 100%"
              }
              style={{ zIndex: 2 }}
              py={75}
            >
              <Image mx={"auto"} w={"50%"} src={TransformedColumnImage} />
              <Text lh={"lg"} size={"xs"} c={"dimmed"}>
                Transformed or calculated column. Data samples not available at
                this stage.
              </Text>
            </Stack>
          </Center>
        ) : null}
        {column.type === "transformation"
          ? [...Array(50).keys()].map((idx) => (
              <Box key={idx}>
                <Skeleton
                  height={8}
                  width={`${getConsistentRandom(idx, column.id)}%`}
                  radius="xl"
                />
              </Box>
            ))
          : Array.isArray(column.data_sample) &&
            column.data_sample?.map((value, index) => {
              const formatted = formatDataSample(value);
              return (
                <Box key={index}>
                  {value === undefined || value === null || value === "" ? (
                    <Text size="sm" ff={"monospace"} c="dimmed">
                      empty
                    </Text>
                  ) : (
                    <Tooltip
                      multiline
                      maw={"25rem"}
                      label={
                        <Text size="sm" ff={"monospace"}>
                          {formatted}
                        </Text>
                      }
                    >
                      <Text size="sm" ff={"monospace"} truncate="end">
                        {formatted}
                      </Text>
                    </Tooltip>
                  )}
                </Box>
              );
            })}
      </Stack>

      {/* Action buttons */}

      {column.type === "raw" ? (
        <Transition
          keepMounted
          mounted={hovered}
          transition="fade"
          duration={200}
          timingFunction="ease"
        >
          {(styles) => (
            <ActionIcon.Group
              flex={0}
              mx={"auto"}
              style={{
                ...styles,
              }}
            >
              <Tooltip label="Rename column">
                <ActionIcon disabled={isPosting} variant="subtle">
                  <BiRename />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Clean column">
                <ActionIcon disabled={isPosting} variant="subtle">
                  <MdOutlineCleaningServices />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Enrich column">
                <ActionIcon disabled={isPosting} variant="subtle">
                  <BiSolidMagicWand />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Remove column">
                <ActionIcon
                  disabled={isPosting}
                  variant="subtle"
                  color="red"
                  onClick={() => postMessage(`Remove "${column.name}" column`)}
                >
                  <BiTrash />
                </ActionIcon>
              </Tooltip>
            </ActionIcon.Group>
          )}
        </Transition>
      ) : null}
    </WrapperColumn>
  );
};
