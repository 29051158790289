import {
  Card,
  CardSection,
  Center,
  Group,
  Image,
  Indicator,
  Loader,
  ScrollArea,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import React, { useState } from "react";
import { AddColumn } from "./AddColumn";
import { Chat } from "./Chat";
import { Column } from "./Column";
import { useReportBuilder } from "./ReportBuilderContext";
import classes from "./Table.module.css";
import { groupBySourceAndTable } from "./utils";

import SalesforceLogo from "@images/sources/salesforce.svg";

export const Table: React.FC = () => {
  const [chatOpened, setChatOpened] = useState(true);
  const { report, isPending } = useReportBuilder();

  if (!report) {
    return undefined;
  }

  return (
    <Stack flex={1} style={{ overflow: "hidden" }} gap={"xs"}>
      <ScrollArea.Autosize
        flex={1}
        offsetScrollbars
        scrollbars="x"
        styles={{
          root: { display: "flex", flex: 1 },
          viewport: { display: "flex", flex: 1 },
        }}
        classNames={classes}
      >
        <Group flex={1} align="stretch" wrap="nowrap" px={"lg"}>
          {isPending ? (
            <Center flex={1}>
              <Loader />
            </Center>
          ) : (
            <>
              <Chat opened={chatOpened} setOpened={setChatOpened} />
              {Object.entries(groupBySourceAndTable(report.columns))
                /* New, transformed, columns first ("null" source) */
                .sort(([sourceName]) => (sourceName === "null" ? 1 : -1))
                .map(([sourceName, tables]) => (
                  <Card key={sourceName} radius={"md"}>
                    <CardSection
                      py={"xs"}
                      px={"md"}
                      bg={"gray.2"}
                      ta={"center"}
                    >
                      {sourceName === "null" ? (
                        <Group gap={"xs"} justify="center">
                          <Tooltip
                            label={"New data columns created by your companion"}
                          >
                            <Indicator
                              offset={-20}
                              position="middle-start"
                              color="blue"
                              size={10}
                              processing
                              style={{ cursor: "pointer" }}
                            >
                              <Text>New columns</Text>
                            </Indicator>
                          </Tooltip>
                        </Group>
                      ) : (
                        <Group gap={"xs"} justify="center">
                          <Image h={24} src={SalesforceLogo} />
                          <Text>{sourceName}</Text>
                        </Group>
                      )}
                    </CardSection>
                    <CardSection
                      flex={1}
                      display={"flex"}
                      px={"md"}
                      py={"xs"}
                      style={{ overflow: "hidden" }}
                    >
                      <Group flex={1} align="stretch" wrap="nowrap">
                        {Object.entries(tables).map(
                          ([displayTableName, columns]) => (
                            <Card key={displayTableName}>
                              <CardSection py={4} mb={"xs"}>
                                {displayTableName === "null" ? (
                                  <Text>&nbsp;</Text>
                                ) : (
                                  <Text fw={"bold"}>{displayTableName}</Text>
                                )}
                              </CardSection>
                              <CardSection
                                flex={1}
                                display={"flex"}
                                style={{ overflow: "hidden" }}
                              >
                                <Group flex={1} align="stretch" wrap="nowrap">
                                  {columns
                                    .filter((column) => column.visible)
                                    .map((column) => (
                                      <Column
                                        key={column.name}
                                        column={column}
                                      />
                                    ))}
                                </Group>
                              </CardSection>
                            </Card>
                          ),
                        )}
                      </Group>
                    </CardSection>
                  </Card>
                ))}
              <AddColumn report={report} />
            </>
          )}
        </Group>
      </ScrollArea.Autosize>
      <Text p={"sm"} size="xs">
        {chatOpened ? "Hide " : "Open conversation "}
        <Text span c={"dimmed"}>
          ⌘ K
        </Text>
      </Text>
    </Stack>
  );
};
