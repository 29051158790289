import {
  ReportBuilderProvider,
  useReportBuilder,
} from "@components/ReportBuilder/ReportBuilderContext";
import { Table } from "@components/ReportBuilder/Table";
import { Page } from "@layout/Page";
import { Group, Text } from "@mantine/core";
import React from "react";

export const ReportBuilder: React.FC = () => {
  return (
    <ReportBuilderProvider>
      <ReportBuilderContent />
    </ReportBuilderProvider>
  );
};

const ReportBuilderContent: React.FC = () => {
  const { report, isPending } = useReportBuilder();

  if (!report) {
    return null;
  }

  return (
    <Page
      fullWidth
      title={isPending ? "" : report?.report.name}
      subtitle={"Report Builder"}
      rightSection={
        <Group gap={"xs"} ta={"right"}>
          {isPending ? (
            <Text c={"dimmed"} size="xs">
              Loading &hellip;
            </Text>
          ) : (
            <Text c={"dimmed"} size="xs">
              {report.columns.length} column
              {report.columns.length > 1 ? `s` : ``}
            </Text>
          )}
        </Group>
      }
    >
      <Table />
    </Page>
  );
};
