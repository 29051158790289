import type { Database } from "@mm/shared/schemas/supabase/database.types";
import type { ChatCompletionMessageParam } from "openai/resources/index.mjs";
import { z } from "zod";

/**
 * This type represents a column that could be added
 * to a report.
 * We don't need to gather as much information as with existing report columns
 * but if we wanted to we could
 */
export type ReportColumnCandidate = {
  source_name: string;
  table_name: string | null;
  display_table_name: string;

  name: string;
  data_type: string;
  unique_values: number;
  null_percentage: string;
};

/**
 * Contains the information about a column including (if any)
 * the source and table it belongs to.
 */
export type DetailedReportColumn =
  Database["public"]["Tables"]["report_columns"]["Row"] & {
    // those informations are joined from another table
    table_name: string | null;
    display_table_name: string;
    source_name: string;
  };

export type ReportChatMessage = Omit<
  Database["public"]["Tables"]["report_records"]["Row"],
  "message"
> & {
  message: ChatCompletionMessageParam;
};

/**
 * This type represent a fully fledged report, it includes:
 * - The chat history
 * - All the columns from the report, including their table and source information
 * - Data about the report itself
 *
 *  It's not derived from Zod schema for now but will need to be migrated to avoid relying
 *  on supabase type generation
 */
export type Report = {
  report: Database["public"]["Tables"]["reports"]["Row"];
  columns: DetailedReportColumn[];
  messages: ReportChatMessage[];
};

export const metadataParser = z
  .object({
    table_name: z.string(),
    source_name: z.string(),
    dataset_name: z.string(),
    display_table_name: z.string(),
    dimensions: z
      .object({
        column_name: z.string(),
        unique_values: z.number(),
        null_percentage: z.string(),
        data_type: z.string(),
        sample_values: z.string().array().optional(),
      })
      .passthrough()
      .array(),
  })
  .array();

export const foreignKeysParser = z
  .object({
    keys: z.string().optional(),
    via: z
      .object({
        keys: z.string().array(),
        table: z.string(),
      })
      .optional(),
    label: z.string(),
    tables: z.tuple([z.string(), z.string()]),
  })
  .array();

export const compareCandidates = (
  candidate1: ReportColumnCandidate,
  candidate2: ReportColumnCandidate,
) => {
  return (
    candidate1.name == candidate2.name &&
    candidate1.table_name == candidate2.table_name
  );
};
